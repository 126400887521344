.delivery_orders_table {
  background-image: linear-gradient(to bottom, #90d8eb, #caecef);
  min-height: 660px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  padding-bottom: 48px;
}

.mr-16 {
  margin-right: 16px;
}

.cursor-pointer,
.cursor-pointer > input {
  cursor: pointer;
}

thead th,
tbody td {
  padding: 16px !important;
}

.text-left {
  text-align: left;
}

.ml-16 {
  margin-left: 16px;
}

.wp-row-table {
  width: 100% !important;
}

.number-of-locker {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: black;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.select-all label {
  color: #003780;
  text-decoration: underline;
  font-size: 1.3rem;
  font-weight: 600;
  position: absolute;
  bottom: 24px;
  left: 32px;
  letter-spacing: 1px;
}

.date-time span {
  font-weight: 500;
}

.btn-submit-delivery {
  height: 100px;
  width: 100%;
  border: none !important;
  background: linear-gradient(#34dd4d, #2f6709);
  font-size: 2rem !important;
  font-weight: 600 !important;
}

.btn-submit-delivery:hover {
  background: linear-gradient(#3cb429, #255807);
}

.btn-submit-delivery:active {
  background: linear-gradient(#3cb429, #255807);
}

.text-bold {
  font-weight: 600 !important;
}

.button-position {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.form-check-input {
  width: 1.3em !important;
  height: 1.3em !important;
  margin-top: 4px !important;
}

.form-check-input:checked {
  width: 1.3em !important;
  height: 1.3em !important;
  margin-top: 4px !important;
  background-color: #ffffff !important;
  border-color: none !important;
}

.form-check-input:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.form-check-input:checked[type="checkbox"] {
  border: none !important;
  background-image: url("../images/check-icon.svg") !important;
  background-size: 16px;
  background-repeat: no-repeat;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.text-padding {
  padding-top: 0;
  padding-left: 25px;
  padding-right: 25px;
}

.locker-type h4 {
  margin: 0;
  font-weight: 600;
  color: #ffffff;
}

.mb-150 {
  margin-bottom: 150px;
}

@media (min-width: 768px) {
  .pb-desktop-160 {
    padding-bottom: 160px;
  }
}

@media (max-width: 767px) {
  .d-mobile-none{
      display: none;
  }
}

@media (max-width: 567px) {
  .btn-submit-delivery {
    height: 50px;
    font-size: 1rem !important;
  }

  .margin-bottom-30 {
    margin-bottom: 30px;
  }

  .locker-type h4 {
    display: none;
  }
}
