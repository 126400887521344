@font-face {
    font-family: "Helvetica-Neue";
    src: local("Helvetica-Neue"), url(../scss/custom/fonts/HelveticaNeue/Helvetica-Neue.ttf);
}

@font-face {
    font-family: "Helvetica-Neue-Light";
    src: local("Helvetica-Neue-Light"),
        url(../scss/custom/fonts/HelveticaNeue/helveticaneue-light.otf);
}

@font-face {
    font-family: "HelveticaNeue-Bold";
    src: local("HelveticaNeue-Bold"),
        url(../scss/custom/fonts/HelveticaNeue/HelveticaNeue-Bold.otf);
}

body {
   font-family: "Helvetica-Neue" !important;
}

@import "variables";

@import "custom/plugins/toastr";

@import "./delivery-theme.scss";

#body-pana-sl {
    max-width: 820px;
    min-height: 1180px;
    background-image: linear-gradient(-32deg, #54DCF2 0%, #90D8DD 15%, #13A9D9 25%, #5EB9DE 35%, #A5DCE0 50%, #7FD9E4 60%, #29B6E0 75%, #99D1D9 85%, #7CD1D6 95%, #21AFE0 100%);
    margin: 0 auto;
    position: relative;
}

.card-overlay{
    max-width: 820px;
    min-height: 1180px;
    background: linear-gradient(rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.1));
}

button {
    cursor: pointer;
}

.color-white {
    color: #FFFFFF;
}

.color-yellow {
    color: #EBFF00;
}

.font-size-24 {
    font-size: 24px;
}

.dropdown-menu-end {
    left: auto !important;
    right: 0 !important;
}

#page-topbar {
    background-color: #004EAE;
    width: 100%;
    height: 6.5rem;
    .row {
        height: 100%;
        padding: 0 !important;
        margin: 0 !important;
    }
    .logo-label {
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        color: white;
        display: flex;
        font-size: 3rem;
        font-weight: 600;
    }

    #col-back {
        flex: 0 0 15%;
        max-width: 15%;
        vertical-align: middle;
        display: flex;
        .icon-back {
            font-size: 3.5rem;
            align-items: center;
            display: flex;
            vertical-align: middle;
            padding-left: 15px;
            color: white;
            font-weight: 500;
            cursor: pointer;
        }
    }
    #col-logo-label {
        flex: 0 0 70%;
        max-width: 70%;
        vertical-align: middle;
        display: flex;
    }
    #col-user {
        flex: 0 0 15%;
        max-width: 15%;
        vertical-align: middle;
        display: flex;
        .tag-a-switch {
            padding-right: 12px;
        }
        .dropdown {
            vertical-align: middle;
            display: flex;
            margin-right: 0;
            margin-left: auto;
            button {
                background: none;
                border: none;
                width: 100%;
            }
        }
        .tag-a-switch {
            vertical-align: middle;
            display: flex;
            margin-right: 0;
            margin-left: auto;
        }
    }
}

.height-100 {
    height: 100%;
}

.form-input-custom {
    .input-container {
        position: relative;
    }
      
    .input-container > img {
        position: absolute;
        top: 20px;
        left: 30px;
    }
    
    .input-container > input {
        padding-left: 100px;
        height: 90px;
        font-size: 2.2rem;
        letter-spacing: 1px;
    }
    .invalid-feedback {
        display: inline-block;
        font-size: 20px;
    }

    .inputFocus {
        box-sizing: border-box;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        outline: none;
    }
}

.letter-spacing {
    letter-spacing: 7px !important;
}

.btn-submit-custom {
    padding: 20px;
    border: none !important;
    background:linear-gradient(#34DD4D, #2F6709);
    font-size: 2.5rem;
    font-weight: 600 !important;
    letter-spacing: 1px;
}

.btn-width-submit {
    width: 20rem;
}

.btn-submit-custom:hover {
    background:linear-gradient(#3cb429, #255807);
}

.btn-submit-custom:active {
    background:linear-gradient(#3cb429, #255807);
}

.btn-keys {
    width: 120px;
    height: 120px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50% !important;
    background-color: #A2E4ED !important;
    border: none !important;
    color: #006B7E !important;
    span {
        font-size: 3rem;
        font-weight: bold;
    }
    i{
        font-size: 3rem;
        font-weight: 500;
    }
}

.btn-keys:active {
    background-color: #004EAE !important;
    color: #FFFFFF !important;
}

.cursor-delivery .locker-square.status-Abnormal,
.cursor-delivery .locker-square.status-Empty,
.cursor-delivery .locker-square.status-Opened,
.cursor-return-counter .locker-square.status-Opened,
.customer-lockers .locker-square.status-Opened {
    cursor: pointer;
} 

.locker-square {
    width: 130px;
    height: 110px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px !important;
    padding: 10px;
    position: relative;
    i {
        font-size: 1.6rem;
        font-weight: bold;
        color: #FFFFFF;
    }
}

.locker-square.status-Empty {
    background-color: #000000;
}

.locker-square.status-Abnormal,
.locker-square.status-Locked {
    background-color: #FF0000;
}

.locker-square.status-Abnormal {
    .locker-number {
        background-color: #FFFFFF;
        color: #FF0000;
    }
}

.locker-square.status-Opened {
    background-color: #179600;
    .locker-number {
        background-color: #FFFFFF;
        color: #179600;
    }
}

.dev-center {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
}

.icon-status {
    text-align: end;
    height: 40px;
    width: 40px;
    position: absolute;
    right: 15px;
    top: 15px;
}

.locker-number {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 4px solid #FFFFFF;
    font-size: 2.5rem;
    font-weight: 600;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    bottom: 15px;
    left: 15px;
}

.col-6-locker-left,
.col-6-locker-right {
    flex: 0 0 48%;
    max-width: 48%;
}

.col-hr-locker {
    flex: 0 0 4%;
    max-width: 4%;
}

.hr-middle {
    border-left: 3px solid #FFFFFF;
    height: 70%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 86px;
}

.div-bottom {
    position: absolute;
    bottom: 30px;
    width: 100%;
}

.locker-top-padding {
    padding-top: 220px;
}

.form-control:focus{
    box-shadow: none !important;
}

.mt-20{
    margin-top: 20px !important;
}

.dropdown-menu-end.dropdown-menu.show{
    transform: translate(12px, 104px) !important;
    min-width: 105px;
    padding: 0;
    background-color: #CAECEF;
}

.width-collected-btn{
    max-width: 530px;
    padding: 20px;
}

.mb-32{
    margin-bottom: 32px;
}

@media only screen and (width: 1920px) and (max-height: 980px) {
    #body-pana-sl {
        min-height: 937px;
    }

    .card-overlay{
        min-height: 937px;
    }

    h1{
        font-size: 1.4rem;
    }

    h1,h2,h3{
        margin-bottom: 0;
    }

    #page-topbar{
        height: 5rem;
        .logo-label{
            font-size: 2.25rem;
        }
        #col-user {
            .dropdown{
                button{
                    img{
                        max-width: 80%;
                    }
                }
            }
            .tag-a-switch {
                img{
                    max-width: 80%;
                }
            }
        }
        #col-back{
            .icon-back{
                font-size: 3rem;
            }

        }
    }

    .form-input-custom {
        .input-container > input {
            height: 65px;
            font-size: 1.2rem;
        }

        .input-container > img {
            top: 14px;
            max-width: 40px;
        }
    }

    .btn-submit-custom {
        font-size: 1.5rem;
    }

    .btn-keys {
        width: 100px;
        height: 100px;
    }

    .button-position{
        bottom: 20px;
    }

    .mb-32{
        margin-bottom: 0;
    }

    .pt-4{
        padding-top: 0 !important;
    }

    .locker-square {
        width: 125px;
        height: 105px;
        .icon-status {
            right: 12px;
            top: 12px;
            i {
                font-size: 1.4rem;
            }
        } 
    }

    .dropdown-menu-end.dropdown-menu.show{
        transform: translate(12px, 80px) !important;
    }

    .delivery_orders_table {
        min-height: 500px;
    }
}

@media (min-width: 768px) {
    .container,
    .container-md,
    .container-sm {
        max-width: 760px !important;
    }
}

@media (max-width: 767px) {
    .col-6-locker-left,
    .col-6-locker-right {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .mobile-margin{
        margin-bottom: 250px;
    }

    .col-hr-locker{
        display: none;
    }

    .locker-type h4 {
        display: none;
    }

    #col-user {
        .dropdown {
            button {
                background: none;
                border: none;
                width: 100%;
            }
            .btn-secondary{
                padding: 0 12px 0 0;
            }
        }
    }

    thead th {
        font-size: 18px;
    }

    .mobile-p-0{
        padding: 0 !important;
    }

}

@media (max-width: 567px) {
    #page-topbar{
        .logo-label{
            font-size: 1.75rem;
        }
        #col-back {
            flex: 0 0 20%;
            max-width: 20%;
            .icon-back {
                font-size: 1.75rem;
            }
        }
        #col-logo-label {
            flex: 0 0 60%;
            max-width: 60%;
        }
        #col-user{
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    .btn-keys {
        width: 70px;
        height: 70px;
    }
    .btn-keys span, .btn-keys i {
    font-size: 2rem;
    }

    .btn-submit-custom {
        font-size: 1.5rem !important;
    }

    .width-collected-btn{
        width: unset;
    }

    .font-24{
        font-size: 24px;
    }

    .form-input-custom .input-container > img {
        top: 8px;
        left: 16px;
        max-width: 36px;
    }

    .form-input-custom .input-container > input {
        padding-left: 60px;
        height: 50px;
        font-size: 1.2rem;
    }

    .letter-spacing {
        letter-spacing: 3px !important;
    }

    .mt-20{
        margin-top: 16px !important;
    }

}

@media (max-width: 420px) {
    #body-pana-sl, .card-overlay {
        min-height: unset;
        height: 100vh;
        overflow: hidden;
    }
    #page-topbar{
        height: 4rem;
        .logo-label {
            font-size: 1.5rem;
        }
        #col-user {
            .dropdown{
                button{
                    img{
                        max-width: 85%;
                    }
                }
            }
            .tag-a-switch {
                img{
                    max-width: 85%;
                }
            }
        }
    }

    .responsive-height {
        min-height: 820px !important;
        height: auto !important;
    }

    .max-width{
        max-width: 81% !important;
    }

    .btn-submit-custom {
        font-size: 1.25rem !important;
    }

    .dropdown-menu-end.dropdown-menu.show {
        transform: translate(12px, 64px) !important;
    }

    .delivery_orders_table {
        min-height: 460px;
        overflow: auto;
        table{
            width: 520px;
            overflow: scroll;
        }
    }
}
